import React, { FunctionComponent } from "react";

import { Skeleton, Container, Stack } from "@mui/material";

import PostCardSkeleton from "./PostCard";

interface Props {
  type?: "image" | "video";
  withBanner?: boolean;
  withTitle?: boolean;
}

const GAP = "30px";

const PostGridSkeleton: FunctionComponent<Props> = ({
  type,
  withBanner,
  withTitle,
}) => {
  return (
    <Container
      component="section"
      disableGutters
      maxWidth={false}
      className="mb-28 justify-center pt-8 [&>*]:mx-auto [&>*]:w-[95%]"
    >
      <Stack>
        {withBanner && (
          <Skeleton
            variant="rounded"
            height={50}
            sx={{ width: { xs: "100%", lg: "calc(100% - 328px)" }, mb: 2 }}
          />
        )}
        {withTitle && (
          <Stack
            sx={{ width: { xs: "100%", lg: "calc(100% - 328px)" }, mb: 2 }}
            alignItems="center"
          >
            <Skeleton variant="text" height={60} width={200} />
          </Stack>
        )}
      </Stack>
      <Stack direction="row" justifyContent="space-between" position="relative">
        <Stack alignItems="center" flex="auto" gap={GAP} width="100%">
          <Stack
            direction="row"
            gap={GAP}
            flexWrap="wrap"
            justifyContent={{ xs: "center", md: "space-between" }}
            className="w-full"
          >
            <PostCardSkeleton type={type} featured />
            <PostCardSkeleton type={type} featured />
          </Stack>

          <Stack className="flex w-full items-center justify-center lg:hidden">
            <Skeleton width={300} height={250} variant="rectangular" />
          </Stack>

          <Stack
            direction="row"
            gap={GAP}
            flexWrap="wrap"
            width="100%"
            justifyContent={{ xs: "center", xl: "space-between" }}
          >
            <PostCardSkeleton type={type} />
            <PostCardSkeleton type={type} />
            <PostCardSkeleton type={type} />
            <PostCardSkeleton type={type} />
          </Stack>
        </Stack>
        <Stack width={300} minWidth={300} className="ml-7 hidden lg:flex">
          <Stack spacing={3.25} className="w-full items-center">
            <Skeleton variant="rectangular" width={300} height={250} />
            <Skeleton variant="rectangular" width={300} height={600} />
          </Stack>
        </Stack>
      </Stack>
    </Container>
  );
};

PostGridSkeleton.defaultProps = {
  type: "image",
};

export default PostGridSkeleton;
