import { ReactNode, useState } from "react";

import LinearProgress from "@mui/material/LinearProgress";
import { useInView } from "react-intersection-observer";

import type { FunctionComponent } from "react";

interface Props {
  children: ReactNode;
  fallback?: ReactNode;
}

export const RenderInView: FunctionComponent<Props> = ({
  children,
  fallback,
}) => {
  const [showComponent, setshowComponent] = useState(false);
  const { ref } = useInView({
    onChange: (inView) => {
      // We want to show the component when it's in view but we don't want to hide it again when it's out of view.
      if (inView && !showComponent) setshowComponent(true);
    },
  });

  return (
    <div ref={ref}>
      {showComponent ? children : fallback ?? <LinearProgress />}
    </div>
  );
};
