import React from "react";

import dynamic from "next/dynamic";

import { sizeMaps } from "lib/dfp";
import { ErrorBoundary } from "ui/src/ErrorHandling";
import { RenderInView } from "ui/src/RenderInView";

import PostGridSkeleton from "@/components/skeletons/PostGrid";
import Page from "@/layouts/BasePage";

import type { NextPage } from "next";
import type { SiteProps } from "utils/types";
import type { AdSlotsMap } from "utils/types/ads";
import type { ImagePost, VideoPost } from "utils/types/content";

const FeaturedPostsGrid = dynamic(
  () => import("@/components/Content/FeaturedPostsGrid"),
  { ssr: false, loading: () => <PostGridSkeleton withBanner /> }
);

interface Props extends SiteProps {
  imagePosts: ImagePost[];
  videoPosts: VideoPost[];
}

const ads: AdSlotsMap = {
  dleaderboard: {
    adUnit: "/TPL_Home_Desk_Leaderboard_728Flex",
    size: sizeMaps.leaderboard,
    divId: "div-gpt-ad-1691440409686-0",
  },
  mleaderboard: {
    adUnit: "/TPL_Home_Mobile_Leaderboard_320",
    size: sizeMaps.mobile,
    divId: "div-gpt-ad-1691707616631-0",
  },
};

const articleAds: AdSlotsMap = {
  dflex1: {
    adUnit: "/TPL_Home_Desk_MPU_300",
    size: sizeMaps.flexBoxSmall,
    divId: "div-gpt-ad-1691441558799-0",
  },
  dflex2: {
    adUnit: "/TPL_Home_Desk_MPU_300_2",
    size: sizeMaps.flexBoxSmall,
    divId: "div-gpt-ad-1691705134287-0",
  },
  dflex3: {
    adUnit: "/TPL_Home_Desk_MPU_300_3",
    size: sizeMaps.flexBoxLarge,
    divId: "div-gpt-ad-1691705238113-0",
  },
  mflex1: {
    adUnit: "/TPL_Home_Mobile_MPU_320_1",
    size: sizeMaps.mobile,
    divId: "div-gpt-ad-1691707779536-0",
  },
};

const videoAds: AdSlotsMap = {
  dflex1: {
    adUnit: "/TPL_Home_Desk_MPU_300_4",
    size: sizeMaps.flexBoxSmall,
    divId: "div-gpt-ad-1691705787089-0",
  },
  dflex2: {
    adUnit: "/TPL_Home_Desk_MPU_300_5",
    size: sizeMaps.flexBoxSmall,
    divId: "div-gpt-ad-1691705951435-0",
  },
  dflex3: {
    adUnit: "/TPL_Home_Desk_MPU_300_6",
    size: sizeMaps.flexBoxLarge,
    divId: "div-gpt-ad-1691706119337-0",
  },
  mflex1: {
    adUnit: "/TPL_Home_Mobile_MPU_320_2",
    size: sizeMaps.mobile,
    divId: "div-gpt-ad-1691707974285-0",
  },
};

const Home: NextPage<Props> = ({ imagePosts, videoPosts, ...props }) => {
  return (
    <Page {...props} hideBreadCrumbs>
      <ErrorBoundary>
        <RenderInView fallback={<PostGridSkeleton withBanner />}>
          <FeaturedPostsGrid
            posts={imagePosts}
            title="Featured"
            adSlots={{ ...ads, ...articleAds }}
          />
        </RenderInView>
      </ErrorBoundary>
      <ErrorBoundary>
        <RenderInView fallback={<PostGridSkeleton type="video" />}>
          <FeaturedPostsGrid
            posts={videoPosts}
            title="Hot Videos"
            adSlots={{ ...ads, ...videoAds }}
          />
        </RenderInView>
      </ErrorBoundary>
    </Page>
  );
};

export async function getStaticProps() {
  const { fetchSiteProps } = await import("@/utils/fetchers");
  const siteProps = await fetchSiteProps();

  const { getContentService } = await import("api/content-service");
  const { videoPosts, imagePosts } = await getContentService()
    .posts()
    .getHighlightedPosts();

  return {
    props: {
      ads,
      imagePosts,
      videoPosts,
      ...siteProps,
      metadata: {
        title: "Welcome to The Players' Lounge | Find Your Team",
      },
    },
    revalidate: 60,
  };
}

export default Home;
